// @ts-strict-ignore

import { appName } from '../../__config__/env.json'
import datalayerPush from './data-layer'

export const track = (action, label, value, callback, nonInteractive, category = appName) => {
  datalayerPush({
    event: 'gaTriggerEvent',
    gaEventCategory: category,
    gaEventAction: action,
    gaEventLabel: label,
    gaEventValue: value,
    eventCallback: callback,
    gaNonInteractive: nonInteractive || 0,
  })
}

const EVENT_NAME = 'gaTriggerEvent'

export const trackEvent = (trackingObject) => {
  if (trackingObject?.tracking) {
    const {
      tracking: { category, action, label },
    } = trackingObject

    datalayerPush({
      event: EVENT_NAME,
      gaEventCategory: category.value,
      gaEventAction: action.value,
      gaEventLabel: label.value,
    })
  }
}

export const gtmTrack = (action, category, label) =>
  trackEvent({
    tracking: {
      category: {
        value: category,
      },
      action: {
        value: action,
      },
      label: { value: label },
    },
  })

export const trackElementClicked = (elementName, elementText, elementType) => {
  const id = elementName

  datalayerPush({
    event: 'nerd.element.clicked',
    nerd: {
      event: {
        id: id,
      },
      element: {
        [id]: {
          /* eslint-disable camelcase */
          element_name: elementName,
          element_text: elementText,
          element_type: elementType,
          /* eslint-enable camelcase */
        },
      },
    },
  })
}
