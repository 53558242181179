import { get } from './utils/use-fetch'

interface ExistingConsentParams {
  email?: string
  usci?: string
  consentName: string
  consentType?: string
}

export const requestUsciFromEmail = async (email: string): Promise<any> => {
  const path = `/api/email/${encodeURIComponent(email)}`

  const response = await get(path).then((data: any): string => data?.id || '')

  return response
}

const requestExistingConsentFromUsci = async (
  usci: string,
  params: ExistingConsentParams,
): Promise<any> => {
  const path = `/api/customer/${usci}/consent`

  const response = await get(path).then((existingConsent: any) => {
    const selectedData =
      existingConsent &&
      existingConsent?.filter((consent: any) => consent.name === params.consentName)

    return selectedData.length > 0
  })

  return response
}

export const getExistingConsent = async (params: ExistingConsentParams): Promise<any> => {
  let usci: string = params.usci || ''
  if (params.email) {
    usci = await requestUsciFromEmail(params.email)
  }

  return !!usci && requestExistingConsentFromUsci(usci, params)
}
