// @ts-strict-ignore
import getWindow from './get-window'

const doc = getWindow().document || {}

const daysToMilliseconds = (days) => days * 24 * 60 * 60 * 1000

const createCookieExpiry = (ms) => {
  const date = new Date()
  date.setTime(date.getTime() + ms)

  return ` expires=${date.toGMTString()};`
}

/**
 * @constant {string} CONSENT_COOKIE - The consent cookie name.
 */
export const CONSENT_COOKIE = 'cookie-preferences'

/**
 * @constant {number} PREFERENCES_EXPIRY_DAYS - The number of days the preferences should expire on.
 */
export const PREFERENCES_EXPIRY_DAYS = 1000

/**
 * Parse an HTTP Cookie header string and return an object of all cookie name-value pairs.
 *
 * @see https://www.30secondsofcode.org/js/s/parse-cookie/
 * @param {string} str - The cookie string to parse
 * @returns {Object}
 */
export const parseCookie = (str) =>
  str
    .split(';')
    .map((v) => v.split('='))
    .reduce((acc, [key, value]) => {
      if (!key) return acc
      acc[decodeURIComponent(key.trim())] = decodeURIComponent(value.trim())
      return acc
    }, {})

export const readCookie = (name) => {
  const cookies = parseCookie(doc.cookie || '')
  if ({}.hasOwnProperty.call(cookies, name)) {
    return JSON.parse(cookies[name])
  }

  return null
}

export const readCookieRaw = (name) => {
  const cookies = parseCookie(doc.cookie || '')
  if ({}.hasOwnProperty.call(cookies, name)) {
    return cookies[name]
  }

  return null
}

export const createCookie = (name, value, days) => {
  const expires = days ? createCookieExpiry(daysToMilliseconds(days)) : ''

  const cookie = `${name}=${value};${expires} path=/`
  return (doc.cookie = cookie)
}

export const updateConsentCookie = (key, value) => {
  const consentCookie = readCookie(CONSENT_COOKIE)
  if (!consentCookie) {
    if (getWindow().Sentry) {
      getWindow().Sentry.captureException(new Error('No consent cookie found'))
    }

    return
  }

  const updatedCookie = JSON.stringify(Object.assign({}, consentCookie, { [key]: value }))

  createCookie(CONSENT_COOKIE, updatedCookie, PREFERENCES_EXPIRY_DAYS)
}
