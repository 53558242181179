import ConfigSetting from './config'
import { parseCookies } from './utils/cookies'
import { hasWindow } from './utils/misc'
import { post } from './utils/use-fetch'

const UPDATE_EVENT_KEY = 'consent.updated'

export interface Consent {
  brand?: string
  name: string
  status: boolean
  source: string
  sourceUrl: string
  'source-url'?: string
}

export interface UpdateConsentParams {
  email?: string
  uuid?: string
  source?: string
  source_url?: string
  consents: Array<Consent>
}

export interface ConsentUpdatedInfo {
  usci: string
}

export const updateConsents = async (params: UpdateConsentParams): Promise<any> => {
  const uuid: string = params.uuid || ''
  const source: string = params.source || ''
  const source_url: string = params.source_url || ''
  const consentsMapped = [...params.consents]

  const body: UpdateConsentParams = {
    consents: consentsMapped.map((item: Consent) => ({
      ...item,
      'source-url': item.sourceUrl,
      brand: ConfigSetting._brand,
    })),
  }

  const call = params.email
    ? post('/api/customer_consent', {
        headers: {
          credentials: 'include',
        },
        body: {
          email: params.email,
          source,
          source_url,
          ...body,
        },
      })
    : post(`/api/customer/${uuid}/consent`, {
        body,
      })

  return call.then(triggerUpdated)
}

function triggerUpdated(response: any) {
  if (!hasWindow()) {
    return response
  }

  const { usci } = parseCookies()

  const event = new CustomEvent<ConsentUpdatedInfo>(UPDATE_EVENT_KEY, {
    detail: { usci },
  })

  dispatchEvent(event)

  return response
}
