/* eslint-disable camelcase */
import { readCookieRaw } from '../utils/cookies'
import uuidv4 from '../utils/guid'

const pageMap = {
  money: [
    '/business-insurance/pm-1',
    '/business/business-insurance',
    '/business/business-insurance/contents-business-insurance',
    '/business/business-insurance/dog-walking-insurance',
    '/business/business-insurance/goods-in-transit-insurance',
    '/business/business-insurance/providers/superscript',
    '/business/business-insurance/public-liability-insurance',
    '/business/business-insurance/self-employed-insurance',
    '/business/business-insurance/shop-insurance',
    '/business/business-insurance/tool-insurance',
    '/business/business-insurance/tradesman',
  ],
  uswitch: [
    '/business-insurance',
    '/business-insurance/pm-1',
    '/business-insurance/professional-indemnity-insurance',
    '/business-insurance/professional-indemnity-insurance/pm-1',
    '/business-insurance/public-liability-insurance',
    '/business-insurance/public-liability-insurance/pm-1',
    '/business-insurance/rising-premium-costs',
    '/business-insurance/trades-insurance/pm-1',
  ],
}

const UtmCampaign = {
  money: {
    '/business-insurance/pm-1': '',
    '/business/business-insurance': '',
    '/business/business-insurance/contents-business-insurance': '-business-contents',
    '/business/business-insurance/dog-walking-insurance': '-dog-walkers',
    '/business/business-insurance/goods-in-transit-insurance': '-goods-in-transit',
    '/business/business-insurance/providers/superscript': '',
    '/business/business-insurance/public-liability-insurance': '-pl',
    '/business/business-insurance/self-employed-insurance': '',
    '/business/business-insurance/shop-insurance': '-shops',
    '/business/business-insurance/tool-insurance': '-tools',
    '/business/business-insurance/tradesman': '-trades',
  },
  uswitch: {
    '/business-insurance': '',
    '/business-insurance/pm-1': '',
    '/business-insurance/professional-indemnity-insurance': '-pi',
    '/business-insurance/professional-indemnity-insurance/pm-1': '-pi',
    '/business-insurance/public-liability-insurance': '',
    '/business-insurance/public-liability-insurance/pm-1': '',
    '/business-insurance/rising-premium-costs': '',
    '/business-insurance/trades-insurance/pm-1': '-tradesman',
  },
}

const BRAND = window.__BRAND__
if (
  ['money', 'uswitch'].includes(BRAND) &&
  pageMap[BRAND].includes(window.location.pathname.replace(/\/$/, ''))
) {
  const CTAs = Array.from([
    ...document.querySelectorAll('a'),
    ...document.querySelectorAll('button'),
  ]).filter((b) => b.innerText.includes('Get') && b.innerText.includes('quote'))
  const getLink = (element) => {
    if (element) {
      if (element.tagName === 'A') {
        return element.href
      } else if (element.tagName === 'FORM') {
        return element.getAttribute('action')
      } else return getLink(element.parentElement)
    }
    return null
  }

  const randomId = uuidv4()
  const widgetParams = {
    cobrand: BRAND,
    utm_source: BRAND,
    utm_campaign: `partner_${BRAND}${
      UtmCampaign[BRAND][window.location.pathname.replace(/\/$/, '')]
    }`,
    p_custid: readCookieRaw('uscd'),
    puid: randomId,
  }
  const body = document.getElementsByTagName('body')[0]
  const hiddenElementForParams = document.createElement('div')
  hiddenElementForParams.style.display = 'none'

  const qbParams = new URLSearchParams(widgetParams).toString()
  hiddenElementForParams.classList.add('qb-launcher')
  hiddenElementForParams.setAttribute('data-qb-params', qbParams)

  body.appendChild(hiddenElementForParams)
  CTAs.forEach((b) => {
    b.addEventListener('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
      window.QB('show')
      const link = getLink(b)
      if (link) {
        const clickoutLink = new URL(link)
        clickoutLink.searchParams.append('c', randomId)

        fetch(clickoutLink)
      } else {
        // eslint-disable-next-line no-console
        console.log('Clickout link not found')
      }
    })
  })

  const head = document.getElementsByTagName('head')[0]

  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = 'https://online-quote.gosuperscript.com/launcher.js'

  const style = document.createElement('style')
  style.type = 'text/css'
  const css = '#qb { z-index: 300 !important; }'
  style.appendChild(document.createTextNode(css))

  head.appendChild(script)
  head.appendChild(style)
}
