import ConfigSettings from '../config'

export interface RequestParams {
  headers?: HeadersInit
  method?: 'GET' | 'POST'
  body?: string
}

interface PostParams {
  headers?: HeadersInit
  body: object
}

const request = (path: string, { headers = {}, ...rest }: RequestParams = {}): Promise<any> => {
  const fullUrl = `${ConfigSettings._apiPath}${path}`

  const requestHeaders: HeadersInit = {
    'X-Client-Id': ConfigSettings._clientId,
    'X-Client-Version': ConfigSettings._clientVersion.toString(),
    ...headers,
  }

  return fetch(fullUrl, {
    ...rest,
    headers: requestHeaders,
  }).then((res) => res.json())
}

export const get = request

export const post = (
  path: string,
  { headers = {}, body = {}, ...rest }: PostParams,
): Promise<any> =>
  request(path, {
    ...rest,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    method: 'POST',
    body: JSON.stringify(body),
  })
