import { get } from './utils/use-fetch'

export interface ConsentText {
  consentName: string
  consentType?: string
}

export interface ConsentTextResponse {
  id: string
  brand: string
  text: string
  name: string
  'created-at': string
  'permission-names': string
}

export const getConsentText = async (params: ConsentText): Promise<ConsentTextResponse> =>
  get(`/api/consent/${params.consentName}`)
